<template>
  <div class="body">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>监控管理</el-breadcrumb-item>
      <el-breadcrumb-item>监控列表</el-breadcrumb-item>
    </el-breadcrumb>

    <iframe :src="src" width="100%" height="100%" frameborder="false">不支持</iframe>
  </div>
</template>

<script>
export default {
  name: "Camera",
  data() {
    return {
      // src: '/doc/page/login.asp',
      src: 'https://www.baidu.com',
    }
  }
}
</script>

<style scoped>
.body {
  height: 100%;
  width: 100%;
}
</style>